import React from 'react';
import { IFormSectionProps } from "./types"
import classNames from "classnames"
import './styles.scss';

function FormSection({ title, children, pb0 = false, disabled, id, noPadding }: IFormSectionProps) {

    return (
        <div
            {...{ id }}
            className={classNames({
                'form-section': true,
                'disabled': disabled
            })}>
            {title && <div className="section-title">{title}</div>}
            <div className={`${pb0 && "pb0"} ${noPadding && "no-padding"} form-section-content`}>
                {children}
            </div>
        </div>
    );
};

export default FormSection;
