import React from "react";
import { useTranslation } from "react-i18next";
import FormSection from "../../../infrastructure/section/form-section";
import ActionButtons from "../../../infrastructure/section/action-buttons-section";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import { getAnswers } from "../../../services/selectors/mainSelector";
import { IQuizWrapperProps } from "./types";

import "./styles.scss";

const QuizWrapper = ({
  id,
  onChange,
  index,
  qNumber: { questionTitle, answerList, questionId },
  qNumber,
  handleNext,
  handleBack,
  hideBackButton = false,
  useTranslate = false,
}: IQuizWrapperProps) => {
  const { t } = useTranslation();
  const answers = useSelector(getAnswers);

  const renderQuestions = () => {
    const q = answerList.map(({ answerId, answer, points }, i) => {
      const checked = answers?.[index]?.summ === points;
      const label = useTranslate ? t(answer) : answer;
      return (
        <FormControlLabel
          key={i}
          value={points}
          control={<Radio {...{ checked }} />}
          {...{ label }}
          onChange={() => onChange(index, answerId, points, questionId)}
          className={`${answers?.[index]?.summ === points && "selected-option"
            }`}
        />
      );
    });
    return q;
  };

  const title = useTranslate ? t(questionTitle) : questionTitle;
  const value = answers && answers?.[index] ? answers?.[index]?.summ : null;
  const answerId = answerList.find(
    (answer) => answer.points === answers?.[index]?.summ
  );

  return (
    <div className="quiz-container">
      <FormSection {...{ id, title }}>
        <FormControl className="radio-btns">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            {...{ value }}
          >
            {renderQuestions()}
          </RadioGroup>
        </FormControl>
      </FormSection>
      <ActionButtons
        {...{ handleNext, handleBack, hideBackButton }}
        disabled={!answers?.[index]}
      />
    </div>
  );
};

export default QuizWrapper;
