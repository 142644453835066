import {
    SET_ANSWER,
    CHANGE_LANGUAGE,
    CHANGE_PAGE,
    SET_ALL_DATA,
    SAVE_INCOMMING_QUESTIONS_DATA,
    SAVE_TOKEN,
    SAVE_SURVEY_TOKEN
} from '../actions';

import { loadState } from '../../infrastructure/components/helpers/localstorage';


import i18n from "../../i18n";
import { storePrefLang } from "../Prefs";
import moment from "moment";

export const setAnswer = (payload) => {
    return { type: SET_ANSWER, payload };
};

export const saveToken = (token) => {
    return { type: SAVE_TOKEN, payload: token };
};

export const saveSurveyToken = (surveyToken) => {
    return { type: SAVE_SURVEY_TOKEN, payload: surveyToken };
}
export const saveIncommingQuestionsData = (payload) => {
    return { type: SAVE_INCOMMING_QUESTIONS_DATA, payload };
}

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
export const changePage = (page: number) => {
    return { type: CHANGE_PAGE, payload: page };
};

export const setAllData = () => {
    return { type: SET_ALL_DATA, payload: loadState() };
};