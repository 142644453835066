import React from "react";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MainPage from "./pages/main-page"

import "./styles.scss";

const App = () => {
    return (
        <>
             <Router>
                    <Switch>
                        <Route path="/:langId" component={MainPage} />
                        <Route component={MainPage} />       
                    </Switch>
           </Router>
        </>
    );
};

export default App;
