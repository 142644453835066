import React from "react";
import { ErrorIcon } from "../../images/icon-error";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import "./styles.scss";

const MainPage = () => {
    const { t } = useTranslation();
    return (
        <div className="error-page">
            <h1>{t('Error_text')} =(</h1>
            <ErrorIcon />
            <Button
                onClick={() => window.location.reload()}
                className={`send-button`}
                variant="contained"
            >
                {t("Reload")}
            </Button>
        </div>
    );
};

export default MainPage;
