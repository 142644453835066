const questionsMockArray = [{
  questionId: 1,
  questionTitle: 'Q_1',
  answerList: [
    { answerId: 1, answer: 'Q_1_answer_1', points: 100 },
    { answerId: 2, answer: 'Q_1_answer_2', points: 75 },
    { answerId: 3, answer: 'Q_1_answer_3', points: 50 },
    { answerId: 4, answer: 'Q_1_answer_4', points: 25 },
    { answerId: 5, answer: 'Q_1_answer_5', points: 0 },
  ]
},
{
  questionId: 2,
  questionTitle: 'Q_2',
  answerList: [
    { answerId: 6, answer: 'Q_2_answer_1', points: 100 },
    { answerId: 7, answer: 'Q_2_answer_2', points: 60 },
    { answerId: 8, answer: 'Q_2_answer_3', points: 40 },
    { answerId: 9, answer: 'Q_2_answer_4', points: 20 },
    { answerId: 10, answer: 'Q_2_answer_5', points: 0 },
  ]
},
{
  questionId: 3,
  questionTitle: 'Q_3',
  answerList: [
    { answerId: 11, answer: 'Q_3_answer_1', points: 100 },
    { answerId: 12, answer: 'Q_3_answer_2', points: 75 },
    { answerId: 13, answer: 'Q_3_answer_3', points: 50 },
    { answerId: 14, answer: 'Q_3_answer_4', points: 25 },
    { answerId: 15, answer: 'Q_3_answer_5', points: 0 },
  ]
},
{
  questionId: 4,
  questionTitle: 'Q_4',
  answerList: [
    { answerId: 16, answer: 'Q_4_answer_1', points: 100 },
    { answerId: 17, answer: 'Q_4_answer_2', points: 75 },
    { answerId: 18, answer: 'Q_4_answer_3', points: 50 },
    { answerId: 19, answer: 'Q_4_answer_4', points: 25 },
    { answerId: 20, answer: 'Q_4_answer_5', points: 0 },
  ]
},
{
  questionId: 5,
  questionTitle: 'Q_5',
  answerList: [
    { answerId: 21, answer: 'Q_5_answer_1', points: 100 },
    { answerId: 22, answer: 'Q_5_answer_2', points: 85 },
    { answerId: 23, answer: 'Q_5_answer_3', points: 40 },
    { answerId: 24, answer: 'Q_5_answer_4', points: 0 },
  ]
},
{
  questionId: 6,
  questionTitle: 'Q_6',
  answerList: [
    { answerId: 25, answer: 'Q_6_answer_1', points: 100 },
    { answerId: 26, answer: 'Q_6_answer_2', points: 80 },
    { answerId: 27, answer: 'Q_6_answer_3', points: 60 },
    { answerId: 28, answer: 'Q_6_answer_4', points: 40 },
    { answerId: 29, answer: 'Q_6_answer_5', points: 1 },
    { answerId: 30, answer: 'Q_6_answer_6', points: 0 },
  ]
},
{
  questionId: 7,
  questionTitle: 'Q_7',
  answerList: [
    { answerId: 31, answer: 'Q_7_answer_1', points: 100 },
    { answerId: 32, answer: 'Q_7_answer_2', points: 75 },
    { answerId: 33, answer: 'Q_7_answer_3', points: 50 },
    { answerId: 34, answer: 'Q_7_answer_4', points: 25 },
    { answerId: 35, answer: 'Q_7_answer_5', points: 10 },
    { answerId: 36, answer: 'Q_7_answer_6', points: 0 },
  ]
},
{
  questionId: 8,
  questionTitle: 'Q_8',
  answerList: [
    { answerId: 37, answer: 'Q_8_answer_1', points: 100 },
    { answerId: 38, answer: 'Q_8_answer_2', points: 65 },
    { answerId: 39, answer: 'Q_8_answer_3', points: 35 },
    { answerId: 40, answer: 'Q_8_answer_4', points: 15 },
    { answerId: 41, answer: 'Q_8_answer_5', points: 0 },
  ]
},


// {
//   questionId: 9,
//   questionTitle: 'Q_4',
//   answerList: [
//     { answerId: 16, answer: 'Q_4_answer_1', points: 100 },
//     { answerId: 17, answer: 'Q_4_answer_2', points: 75 },
//     { answerId: 18, answer: 'Q_4_answer_3', points: 50 },
//     { answerId: 19, answer: 'Q_4_answer_4', points: 25 },
//     { answerId: 20, answer: 'Q_4_answer_5', points: 0 },
//   ]
// },
// {
//   questionId: 10,
//   questionTitle: 'Q_5',
//   answerList: [
//     { answerId: 21, answer: 'Q_5_answer_1', points: 100 },
//     { answerId: 22, answer: 'Q_5_answer_2', points: 85 },
//     { answerId: 23, answer: 'Q_5_answer_3', points: 40 },
//     { answerId: 24, answer: 'Q_5_answer_4', points: 0 },
//   ]
// },
// {
//   questionId: 11,
//   questionTitle: 'Q_6',
//   answerList: [
//     { answerId: 25, answer: 'Q_6_answer_1', points: 100 },
//     { answerId: 26, answer: 'Q_6_answer_2', points: 80 },
//     { answerId: 27, answer: 'Q_6_answer_3', points: 60 },
//     { answerId: 28, answer: 'Q_6_answer_4', points: 40 },
//     { answerId: 29, answer: 'Q_6_answer_5', points: 1 },
//     { answerId: 30, answer: 'Q_6_answer_6', points: 0 },
//   ]
// },
// {
//   questionId: 12,
//   questionTitle: 'Q_4',
//   answerList: [
//     { answerId: 16, answer: 'Q_4_answer_1', points: 100 },
//     { answerId: 17, answer: 'Q_4_answer_2', points: 75 },
//     { answerId: 18, answer: 'Q_4_answer_3', points: 50 },
//     { answerId: 19, answer: 'Q_4_answer_4', points: 25 },
//     { answerId: 20, answer: 'Q_4_answer_5', points: 0 },
//   ]
// },
// {
//   questionId: 13,
//   questionTitle: 'Q_5',
//   answerList: [
//     { answerId: 21, answer: 'Q_5_answer_1', points: 100 },
//     { answerId: 22, answer: 'Q_5_answer_2', points: 85 },
//     { answerId: 23, answer: 'Q_5_answer_3', points: 40 },
//     { answerId: 24, answer: 'Q_5_answer_4', points: 0 },
//   ]
// },
// {
//   questionId: 14,
//   questionTitle: 'Q_6',
//   answerList: [
//     { answerId: 25, answer: 'Q_6_answer_1', points: 100 },
//     { answerId: 26, answer: 'Q_6_answer_2', points: 80 },
//     { answerId: 27, answer: 'Q_6_answer_3', points: 60 },
//     { answerId: 28, answer: 'Q_6_answer_4', points: 40 },
//     { answerId: 29, answer: 'Q_6_answer_5', points: 1 },
//     { answerId: 30, answer: 'Q_6_answer_6', points: 0 },
//   ]
// },
]

export const updatedQuestionDataMock = {
  surveyId: 23,
  en: { questionItems: [ ...questionsMockArray ]},
  ro: { questionItems: [ ...questionsMockArray ]},
  ru: { questionItems: [ ...questionsMockArray ]},
}

export const scrollToElement = elem => {
  const element = document.getElementById(elem)
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  const needScrollToMyDivInPX = elemRect.top - bodyRect.top

  window.scrollTo({
    top: needScrollToMyDivInPX,
    behavior: 'smooth',
  })
}

export const isEmailValid = (email) => {
  /* eslint-disable */
  const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

export const renderSuccessTextOrStatus = (state = "status", t, totalPoints) => {
  let text

  const isVulnerable = 0 <= totalPoints && totalPoints <= 39
  const isCoping = 40 <= totalPoints && totalPoints <= 79

  if (state === "description") {
    if (isVulnerable) {
      text = t('Vulnerable')
    } else if (isCoping) {
      text = t('Coping')
    } else {
      text = t('Healthy')
    }
  } else {
    if (isVulnerable) {
      text = t('Financially_vulnerable')
    } else if (isCoping) {
      text = t('Financially_coping')
    } else {
      text = t('Financially_healthy')
    }
  }

  return text
}


export const RED = "red";
export const YELLOW = "yellow";
export const GREEN = "green";

export const DONE = "done"
export const ENTERED = "entered"
export const ERROR = "error"

export const ERROR_100 = 100;
export const ERROR_200 = 200;
export const ERROR_400 = 400;
export const ERROR_500 = 500;

export const USE_TRANSLATE = true