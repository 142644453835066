import {
  SET_ANSWER,
  CHANGE_PAGE,
  SAVE_INCOMMING_QUESTIONS_DATA,
  SAVE_TOKEN,
  SAVE_SURVEY_TOKEN
} from "../actions";
import { AnyAction } from "redux";

const initialState = {
  answers: {},
  page: 0,
  questionsData: {},
  token: "",
  surveyToken: ""
};

export default function mainReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_ANSWER: {
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.index]: {
            summ: Number(action.payload.points),
          },
        },
      };
    }

    case SAVE_TOKEN:
      return {
          ...state,
          token: action.payload
      };

    case SAVE_SURVEY_TOKEN:
      return {
          ...state,
          surveyToken: action.payload
      };
      
    case CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }

    case SAVE_INCOMMING_QUESTIONS_DATA: {
      return {
        ...state,
        questionsData: action.payload,
      };
    }

    default:
      return state;
  }
}
