import Store from "./store"
import axios from "axios";


Store.subscribe(listener);

function select(state) {
    return state.mainReducer;
}

function selectLang(state) {
    return state.prefsReducer.lng;
}

function listener() {
    const state = select(Store.getState()) || null;
    const lang = selectLang(Store.getState()) || null;

    axios.defaults.headers.common['X-CUSTOMER-TOKEN'] = state.token ;
    axios.defaults.headers.common['X-SURVEY-TOKEN'] = state.surveyToken ;
    axios.defaults.headers.common['Accept-Language'] = lang ;
}

axios.defaults.baseURL = process.env.PUBLIC_URL;

axios.interceptors.response.use(response => response,
    error => {
        return error;
    });

export default axios;
