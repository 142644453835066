import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.mainReducer;
};

export const getAnswers = (state: RootState) => {
    return getMainReducerState(state).answers || [];
};

export const getPage = (state) => {
    return getMainReducerState(state).page;
};

export const getAllQuestions = (state) => {
    return getMainReducerState(state).questionsData;
}