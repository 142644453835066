import React from 'react';
import { IActionButtonsSectionProps } from "./types"
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import './styles.scss';

function ActionButtons({ handleNext, handleBack, hideBackButton = false, disabled, backBtnLabel = "", nextBtnLabel = "" }: IActionButtonsSectionProps) {
    const { t } = useTranslation();
    return (
        <div className={`action-buttons ${hideBackButton && "fl-end"}`}>
            {!hideBackButton && <Button onClick={() => handleBack()} variant="contained" className="next-step-btn prev">
                <ArrowForwardIosIcon fontSize="small" className="rotate" />
            </Button>}

            <Button onClick={() => handleNext()} variant="contained" className={`next-step-btn ${disabled && 'disabled-btn'}`} {...{ disabled }}>
                <ArrowForwardIosIcon fontSize="small" className="details-arrow" />
            </Button>
        </div>
    );
};

export default ActionButtons;
