import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessStep from "../../pages/steps/success-step";
import PreviewForm from "../../pages/steps/preview-form";
import IntroStep from "../../pages/steps/intro-step";
import {
    changePage,
    setAnswer,
    saveIncommingQuestionsData,
    saveToken,
    saveSurveyToken
} from "../../services/actionCreators/mainActionCreator";
import QuizWrapper from "../../infrastructure/components/quiz-wrapper";
import { USE_TRANSLATE } from "../../data/constants";
import { getPage, getAllQuestions } from "../../services/selectors/mainSelector";
import { getCurrentLanguage } from "../../services/selectors/languageSelector";
import Header from "../../infrastructure/components/header";
import StepperComponent from "../../pages/stepper";
import axios from "../../services/API";
import { enqueueSnackbar } from 'notistack';
import ErrorPage from "../../pages/error-page"
import { useTranslation } from "react-i18next";
import "./styles.scss";

// START Code for API mock
// import { createServer } from "miragejs";
// import commonHandler from "../../mock-server/commonHandler";
// const config = require("../../config.json");

// createServer({
//   routes() {
//     commonHandler(this, config.serviceURL);
//     this.passthrough();
//   },
// });
// FINISH Code for API mock

const MainPage = () => {

    const USE_MOCK = false;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
    const [surveyId, setSurveyId] = useState(null);
    const [start, setStart] = useState(false);
    const [showIntroStep, setShowIntroStep] = useState(true);
    const [move, setMove] = useState(false);
    const [moveStep, setMoveStep] = useState(0);
    const [showErrorPage, setShowErrorPage] = useState(false);

    const activeStep = useSelector(getPage);
    const lng = useSelector(getCurrentLanguage);
    const allQuestions = useSelector(getAllQuestions);

    useEffect(() => {
        const timer = setInterval(() => {
            setMoveStep(moveStep + 1);
        }, 1000);
        if (moveStep === 2) {
            clearInterval(timer);

        }

        return () => {
            clearInterval(timer);
        };
    }, [moveStep]);

    useEffect(() => {

        const getInitToken = () => {
            setIsLoading(true);
            axios
                .get(`/api/v1/init`).then(res => {
                    if (res.data) {
                        dispatch(saveToken(res.data))
                        setStart(true);
                    } else {
                        setIsLoading(false);
                        showError(t('Error_token'))
                    }
                })
                .catch(() => {
                    showError(t('Error_token'), false)
                })
        }
        !showIntroStep && getInitToken()

    }, [showIntroStep]);

    useEffect(() => {

        const getSurveyInfo = async () => {
            await axios
                .get(`/api/v1/survey/2`)
                .then((response) => {
                    setIsLoading(false);
                    const { data } = response;
                    const finalData = USE_MOCK ? data.response : data
                    if (finalData) {
                        dispatch(saveIncommingQuestionsData(finalData));
                        setSurveyId(finalData.surveyId);
                        dispatch(saveSurveyToken(finalData.surveyToken));
                        setTotalQuestionsCount(finalData[lng].questionItems.length);
                    } else {
                        showError(t('Error_survey_questions_list'))
                    }
                }).catch(() => {
                    showError(t('Error_survey_questions_list'))
                })
        }
        start && getSurveyInfo()

    }, [start]);

    const showError = (text, redirectToErrorPage = true) => {
        redirectToErrorPage && setShowErrorPage(true)
        enqueueSnackbar(text)
    }

    const setActiveStep = (page) => {
        dispatch(changePage(page));
    };

    const handleNext = (step?: number) => {

        const newStep = step || activeStep + 1
        setActiveStep(newStep);
        setMove(true)
        setIsLoading(false);
        setTimeout(() => {
            setMove(false)
        }, 1000);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const saveAnswers = (index, answerId, points, questionId) => {
        setIsLoading(true);

        const params = {
            surveyId,
            questionId,
            answerId,
            points,
        };
        dispatch(setAnswer({ index, points }));
        axios
            .post(`/api/v1/survey/answer`, { ...params })
            .then(() => {
                    setTimeout(() => {
                        index === totalQuestionsCount ? handleCheck() : handleNext();
                    }, 500);
            })
            .catch(() => {
                showError(t('Error_send_answer'), false)
            })   
    };

    const handleCheck = () => {
        setActiveStep(100);
    };

    const renderStepItemContent = (step: number) => {
        if (step === totalQuestionsCount && step !== 0) {
            return <PreviewForm {...{ setIsLoading, lng, handleNext }} />
        } else if (step === 200) {
            return <SuccessStep />
        } else {
            return (
                <QuizWrapper
                    id={`qw-${step}`}
                    onChange={saveAnswers}
                    qNumber={allQuestions[lng].questionItems[step]}
                    index={step}
                    {...{ handleNext, handleBack }}
                    hideBackButton={step === 0}
                    useTranslate={USE_TRANSLATE}
                />
            );
        }
    };

    const renderSteperContent = () => {
        return (
            <div className="step-content">
                {allQuestions && allQuestions[lng] && renderStepItemContent(activeStep)}
            </div>
        );
    };

    const renderModal = () => {
        return (
            <div className="loader-content">
                {/* <div className="preloader-rain" /> */}
                <CircularProgress />
            </div>
        );
    };

    const showStepper = activeStep < totalQuestionsCount;

    return (
        <>
            {isLoading && renderModal()}
            <div className="kyc-container" id="kyc-container">
                <Header {...{ move }} />
                {showIntroStep && <IntroStep setShowIntroStep={setShowIntroStep} />}
                {showStepper && !showIntroStep && (
                    <StepperComponent {...{ activeStep, totalQuestionsCount }} />
                )}
                {showErrorPage ? <ErrorPage /> : !showIntroStep && renderSteperContent()}
            </div>
        </>
    );
};

export default MainPage;
