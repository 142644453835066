import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import { ISuccessStepProps } from "./types";

import "./styles.scss";

const IntroStep = ({
  setShowIntroStep,
}: ISuccessStepProps) => {
  const { t } = useTranslation();

  return  (
    <div className="intro-container"> 
        <div className="intro-wrapper">
            <span className="intro-title"> 
              {t("Intro_title")}
            </span>
            <span className="intro-text">
              {t("Intro_text")}
            </span>
            <Button
              onClick={() => setShowIntroStep(false)}
              className=""
              variant="contained"
            >
              {t("Intro_btn_label")}
            </Button>
        </div>
    </div>
  );
};

export default IntroStep;