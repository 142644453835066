import React, { useState, useEffect } from "react";
import FormSection from "../../../infrastructure/section/form-section";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentLanguage } from "../../../services/selectors/languageSelector";
import {
  RED,
  YELLOW,
  GREEN,
} from "../../../data/constants";
// import { ISuccessStepProps } from "./types";
import axios from "../../../services/API";
import { enqueueSnackbar } from 'notistack';

import "./styles.scss";

const SuccessStep = () => {
  // { }: ISuccessStepProps
  const { t } = useTranslation();

  const [totalPoints, setTotalPoints] = useState(0);
  const [description, setDescription] = useState({});
  const [progress, setProgress] = useState(0);
  const [color, setColor] = useState(RED);
  const lng = useSelector(getCurrentLanguage);

  useEffect(() => {
    async function fetchScore() {
      await axios
        .get(`/api/v1/survey/score`)
        .then((result) => {
            const { data } = result;
            setTotalPoints(data.score);
            setDescription({ ...data })
        })
        .catch(() => enqueueSnackbar(t('Error_server')));
    }

    fetchScore();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(progress + 1);
    }, 30);
    if (progress === totalPoints) {
      clearInterval(timer);
    } else {
      if (progress > 39 && progress <= 79) {
        setColor(YELLOW);
      } else if (progress >= 80 && progress <= 100) {
        setColor(GREEN);
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [totalPoints, progress]);


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const langCapitalize = capitalizeFirstLetter(lng)
  const resTitle = description[`result${langCapitalize}`]?.resultTitle;
  const resText = description[`result${langCapitalize}`]?.resultText;

  const renderSuccessSection = () => {
    return (
      <FormSection id="step-success">
        <div className="success-container">
          <h1 className="success-title">{t("Your_result")}</h1>

          <div className={`circle-container ${color}`}>
            <div className="border-custom-background"></div>
            <CircularProgress
              variant="determinate"
              value={progress}
              size={150}
            />
            <div className={`point-count ${color}`}>{progress}</div>
          </div>

          <h2 className="customer-status">
            {/* {renderSuccessTextOrStatus("status", t, totalPoints)} OLD VERSION */}
            {resTitle}
          </h2>

          <h4 className="success-subtitle">
            {/* {renderSuccessTextOrStatus("description", t, totalPoints)} OLD VERSION */}
            {resText}
            <span>{t("Soon_text")}</span>
          </h4>
        </div>
      </FormSection>
    );
  };

  return <div className="step-container">{renderSuccessSection()}</div>;
};

export default SuccessStep;
