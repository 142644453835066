import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentLanguage } from '../../../services/selectors/languageSelector';
import { changeLanguage } from '../../../services/actionCreators/mainActionCreator';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { LogoIcon } from "../../../images/icon-logo"
import classNames from "classnames"
import { useParams, Redirect } from 'react-router-dom';

import './styles.scss';

interface ParamTypes {
    langId: string;
}

const Header = ({ move }) => {

    const langByDefault = "ro"

    const { langId } = useParams<ParamTypes>();
    const language = useSelector(getCurrentLanguage);
    const dispatch = useDispatch();

    const [isRedirect, setIsRedirect] = useState(false);
    const [redirectTo, setRedirectTo] = useState("");

    const handleLanguageChange = (lang) => {
        dispatch(changeLanguage(lang))
        setRedirectTo(lang)
        setIsRedirect(true)
    };

    useEffect(() => {
        let correctLang

        switch (langId) {
            case "en":
                correctLang = "en"
                break;
            case "ru":
                correctLang = "ru"
                break;

            default:
                correctLang = "ro"
                break;
        }
        
        if (langId) {
            dispatch(changeLanguage(correctLang))
            setRedirectTo(correctLang)
            setIsRedirect(true)
        } else {
            setIsRedirect(true)
            setRedirectTo(`/${langByDefault}`)
            dispatch(changeLanguage(`${langByDefault}`))
        }
    }, [langId])


    return (
        <div className="header">
            {isRedirect && <Redirect to={`${redirectTo}`} />}
            <FormControl>
                <div className="logo-container">
                    <LogoIcon />
                    <div className={classNames({
                        'icon-rain': true,
                        'move': move
                    })} />
                </div>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    className="select-lang-container"
                    onChange={(e) => handleLanguageChange(e.target.value)}
                >
                    <MenuItem value={"ro"} key={0}>RO</MenuItem>
                    <MenuItem value={"ru"} key={1}>RU</MenuItem>
                    <MenuItem value={"en"} key={2}>EN</MenuItem>
                </Select>
            </FormControl>

        </div>

    );
};

export default Header;
