import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTranslation } from 'react-i18next';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import './css/stepper.scss'

interface IStepperComponent {
    activeStep: number
    totalQuestionsCount: number
}

const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        // top: 20,
        left: 'calc(-50% + 35px)',
        right: 'calc(50% + 35px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#21bb77',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#21bb77',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));



const StepperComponent = ({ activeStep, totalQuestionsCount }: IStepperComponent) => {
    const { t } = useTranslation();
    const questionsCount = []

    for (let i = 0; i < totalQuestionsCount; i++) {
        questionsCount.push(i)
    }
    return (
        <div className="stepper-container">
            <div className="step-counter">
                {t("Question")} {activeStep + 1}/{totalQuestionsCount}
            </div>

            <Stack spacing={4} className="stepper-stack">
                <Stepper alternativeLabel {...{ activeStep }} connector={<StepperConnector />} className="stepper-steps-container">
                    {questionsCount.map((el, index) => {
                        return (
                            <Step key={index} className={`step ${activeStep >= el && 'step-completed'}`}>
                                <StepLabel className="step-label">
                                    {/* {activeStep === index && <Typography className="label-title">{t(`Step_${title}`)}</Typography>} */}
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
            </Stack>
        </div>
    )
}

export default StepperComponent;