import React, { useState } from "react";
import FormSection from "../../../infrastructure/section/form-section";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  isEmailValid,
  DONE,
  ENTERED,
  ERROR,
  ERROR_100,
  ERROR_200,
  ERROR_400,
  ERROR_500,
} from "../../../data/constants";
import { ErrorIcon } from "../../../images/icon-error";
import { SuccessIcon } from "../../../images/icon-success";
import { WarningIcon } from "../../../images/icon-warning";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { IPreviewFormProps } from "./types";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from "../../../services/API";
import InputLabel from '@mui/material/InputLabel';
import { enqueueSnackbar } from 'notistack';

import "./styles.scss";

// START Code for API mock
// import { createServer } from "miragejs";
// import commonHandler from "../../../mock-server/commonHandler";
// const config = require("../../../config.json");

// createServer({
//   routes() {
//     commonHandler(this, config.serviceURL);
//     this.passthrough();
//   },
// });
// FINISH Code for API mock


const PreviewForm = ({
  setIsLoading,
  handleNext
}: IPreviewFormProps) => {
  const { t } = useTranslation();

  const [inputState, setInputState] = useState("");
  const [inputNameSurnameState, setInputNameSurnameState] = useState("");
  const [wasSended, setWasSended] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [errorCode, setErrorCode] = useState(0);

  const sendRequest = async () => {

    const params = {
      email,
      gender,
      ageRange,
      fullName
    }
    await axios
      .post(`/api/v1/survey/email`, { ...params })
      .then((response) => {
          setErrorCode(ERROR_200);
          setWasSended(true)
          setTimeout(() => {
            setIsLoading(false);
            handleNext(200)
          }, 1000);
      })
      .catch(() => {
        enqueueSnackbar(t('Error_server'))
        setErrorCode(ERROR_500)
      });

  }

  const sendEmail = () => {
    setIsLoading(true);
    let resetLoading = false
    setTimeout(() => {
      if (email.length === 0) {
        setErrorCode(ERROR_100);
        resetLoading = true
      } else if (!isEmailValid(email)) {
        setErrorCode(ERROR_400);
        resetLoading = true
      } else {
        sendRequest()
      }

      resetLoading && setIsLoading(false);
    }, 500);
  };

  const checkInputValue = () => {
    return isEmailValid(email) ? setInputState(DONE) : setInputState(ERROR);
  };

  const renderEmptyFieldContent = () => {
    return (
      <div className="error-content">
        <WarningIcon />
        <label>{t("Field_is_required")}</label>
      </div>
    );
  };

  const renderSuccessContent = () => {
    return (
      <div className="success-content">
        <SuccessIcon />
        <label>{t("Success_sended")}</label>
      </div>
    );
  };

  const renderServerErrorContent = () => {
    return (
      <div className="error-content">
        <ErrorIcon />
        <label>{t("Server_error")}</label>
      </div>
    );
  };

  const renderEmailNotCorrentContent = () => {
    return (
      <div className="error-content">
        <WarningIcon />
        <label
          dangerouslySetInnerHTML={{
            __html: t("Email_error"),
          }}
        />
      </div>
    );
  };

  const renderNotifContent = () => {
    let content;

    switch (errorCode) {
      case ERROR_100:
        content = renderEmptyFieldContent();
        break;
      case ERROR_200:
        content = renderSuccessContent();
        break;
      case ERROR_400:
        content = renderEmailNotCorrentContent();
        break;
      case ERROR_500:
        content = renderServerErrorContent();
        break;
      default:
        content = "";
        break;
    }

    return content;
  };

  const onChangeEmailInput = (value) => {
    setErrorCode(null);
    setEmail(value);
  };

  // const emulateError = (checked) => {
  //   setEmulateServerError(checked);
  //   setErrorCode(null);
  //   setEmail("");
  // };
  const isError =
    errorCode == ERROR_100 || errorCode == ERROR_400 || errorCode == ERROR_500;
  const sended = errorCode === ERROR_200;
  const isMale = gender === "male"
  const isFemale = gender === "female"
  const isOther = gender === "other"

  const renderSuccessSection = () => {
    return (
      <FormSection id="step-success">
        <div className="success-container prestep-review-container">

          <h2 className="customer-status">
            {t('Before_preview_title')}
          </h2>

          <h4 className="success-subtitle">
            {t('Before_preview_subtitle')}
          </h4>

          <div className="preview-form">
            <div className="send-email-container">
              <TextField
                label={t('Name_surname')}
                variant="outlined"
                onFocus={() => setInputNameSurnameState(ENTERED)}
                onBlur={() => setInputNameSurnameState(DONE)}
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
                className={`email-input ${inputNameSurnameState} ${errorCode === ERROR_200 && "sended-and-disabled"}`}
              />
            </div>
            <div className="radio-title">{t('Your_gender')}</div>
            <FormControl className="radio-container">

              <RadioGroup
                row
                onChange={(e) => setGender(e.target.value)}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="male"
                  className={`${isMale && "selected-radio"}`}
                  checked={isMale}
                  control={<Radio className={`${isMale && "selected"}`} />}
                  label={t("Is_male")}

                />
                <FormControlLabel
                  value="female"
                  className={`${isFemale && "selected-radio"}`}
                  checked={isFemale}
                  control={<Radio className={`${isFemale && "selected"}`} />}
                  label={t("Is_female")}
                />
                <FormControlLabel
                  value="other"
                  className={`${isOther && "selected-radio"}`}
                  checked={isOther}
                  control={<Radio className={`${isOther && "selected"}`} />}
                  label={t("Is_other")}
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" className={`${!ageRange && 't0'} age-label`}>{t('Age')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="select-age"
                value={ageRange}
                onChange={(e) => setAgeRange(e.target.value)}
              >
                <MenuItem value={"18-25"}>18-25</MenuItem>
                <MenuItem value={"26-35"}>26-35</MenuItem>
                <MenuItem value={"36-50"}>36-50</MenuItem>
                <MenuItem value={"51-75"}>51-75</MenuItem>
                <MenuItem value={"75+"}>75+</MenuItem>
              </Select>
            </FormControl>
            <div className="send-email-container">
              <TextField
                label="Email"
                variant="outlined"
                onFocus={() => setInputState(ENTERED)}
                onBlur={() => checkInputValue()}
                onChange={(e) =>
                  !sended ? onChangeEmailInput(e.target.value) : {}
                }
                disabled={wasSended}
                value={email}
                error={isError}
                className={`email-input ${inputState} ${errorCode === ERROR_200 && "sended-and-disabled"}`}
              />
            </div>
            <div className="erorr-text-container">{renderNotifContent()}</div>

            <Button
              disabled={wasSended || !ageRange || !gender || !fullName}
              onClick={() => sendEmail()}
              className={`${errorCode === ERROR_200 && "sended-and-disabled"} send-button`}
              variant="contained"
            >
              {t("Send")}
            </Button>
          </div>
        </div>
      </FormSection>
    );
  };

  return <div className="step-container">{renderSuccessSection()}</div>;
};

export default PreviewForm;
